import { mapGetters } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import VouchersApiService from "@/core/services/api.service.vouchers";
import moment from "moment";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import ApiService from "@/core/services/api.service";

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "voucher",
  data() {
    return {
      valid: false,
      isSaving: false,
      voucherId: null,
      vouchersApiInitiated: false,
      userToAdd: null,
      users: [],
      usersAreLoading: false,
      searchUsers: "",
      addUsersDisable: false,
      shopToAdd: null,
      shops: [],
      shopsAreLoading: false,
      searchShops: "",
      addShopsDisable: false,
      voucher: {
        description: null,
        price: null,
        validUntil: null,
        users: [],
        shops: []
      },
      usersTableHeaders: [
        { text: "id", value: "userId", sortable: false },
        { text: this.$i18n.t("commonTable.userName"), value: "userName", sortable: false },
        { text: this.$i18n.t("common.delete"), value: "delete", sortable: false }
      ],
      shopsTableHeaders: [
        { text: "id", value: "shopId", sortable: false },
        { text: this.$i18n.t("commonTable.shopName"), value: "shopName", sortable: false },
        { text: this.$i18n.t("common.delete"), value: "delete", sortable: false }
      ],
      requiredRule: [v => !!v || "Field is required"]
    };
  },
  async created() {
    await VouchersApiService.init();
    this.vouchersApiInitiated = true;
    this.voucherId = this.$route.params.voucherId;
    let title = this.voucherId != null ? "Voucher details" : "Insert new voucher";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    if (this.voucherId != null) {
      VouchersApiService.get(``, `${this.voucherId}`)
        .then(response => {
          this.$log.debug("Voucher: ", response.data);
          this.voucher = response.data.result;
          this.loadUsers();
          this.loadShops();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
    this.loadShopsDropDown();
    this.loadUsersDropDown();
  },

  components: {
    ConfirmDialog
  },
  methods: {
    async delShopFromVoucher(item) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete shop " + item.shopName + " for this voucher?"
        )
      ) {
        this.deleteShopFromList(item);
      }
    },
    deleteShopFromList(item) {
      this.$log.debug("Shop to be deleted: " + item);
      const newArray = this.voucher.shops.filter(obj => item.shopId !== obj.shopId);
      this.voucher.shops = newArray;
      this.$log.debug("new array: " + newArray);
    },
    addShopToVoucher() {
      this.$log.debug("shopToAdd: " + this.shopToAdd);
      this.$log.debug("id: " + this.shopToAdd["id"]);
      this.$log.debug("name: " + this.shopToAdd["name"]);
      this.addShopsDisable = true;
      let shopToAdd = {
        shopId: this.shopToAdd["id"],
        shopName: this.shopToAdd["name"]
      };
      this.$log.debug("shopId: " + shopToAdd["shopId"]);
      this.$log.debug("shopName: " + shopToAdd["shopName"]);
      var index = this.voucher.shops.findIndex(x => x.shopId === shopToAdd["shopId"]);
      index === -1 ? this.voucher.shops.push(shopToAdd) : console.log("object already exists");
      // this.voucher.shops.push.apply(this.voucher.shops, shopToAdd);
      this.$log.debug("new array: " + this.voucher.shops);
      this.addShopsDisable = false;
    },
    loadShopsDropDown() {
      // Items have already been loaded
      if (this.shops.length > 0) return;

      // Items have already been requested
      if (this.shopsAreLoading) return;

      this.shopsAreLoading = true;
      ApiService.query("api/shops/dropdown-list")
        .then(res => {
          this.shops = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.shopsAreLoading = false));
    },
    loadUsersDropDown() {
      // Items have already been loaded
      if (this.users.length > 0) return;

      // Items have already been requested
      if (this.usersAreLoading) return;

      this.usersAreLoading = true;
      ApiService.query("api/users/dropdown-list")
        .then(res => {
          this.users = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.usersAreLoading = false));
    },
    loadUsers() {
      VouchersApiService.get(`/${this.voucherId}/users`)
        .then(response => {
          this.$log.debug("Voucher users: ", response.data);
          this.voucher.users = response.data.result;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    loadShops() {
      VouchersApiService.get(`/${this.voucherId}/shops`)
        .then(response => {
          this.$log.debug("Voucher shops: ", response.data);
          this.voucher.shops = response.data.result;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    async delUserFromVoucher(item) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete user " + item.userName + " for this voucher?"
        )
      ) {
        this.deleteUserFromList(item);
      }
    },
    deleteUserFromList(item) {
      this.$log.debug("User to be deleted: " + item);
      const newArray = this.voucher.users.filter(obj => item.userId !== obj.userId);
      this.voucher.users = newArray;
      this.$log.debug("new array: " + newArray);
    },
    addUsersToVoucher() {
      this.$log.debug("userToAdd: " + this.userToAdd);
      this.$log.debug("id: " + this.userToAdd["id"]);
      this.$log.debug("username: " + this.userToAdd["email"]);
      this.addUsersDisable = true;
      let userToAdd = {
        userId: this.userToAdd["id"],
        userName: this.userToAdd["email"]
      };
      this.$log.debug("userId: " + userToAdd["userId"]);
      this.$log.debug("userId: " + userToAdd["userName"]);
      var index = this.voucher.users.findIndex(x => x.userId === userToAdd["userId"]);
      index === -1 ? this.voucher.users.push(userToAdd) : console.log("object already exists");
      this.$log.debug("new array: " + this.voucher.users);
      this.addUsersDisable = false;
    },
    previousState() {
      this.$router.go(-1);
    },
    async validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveVoucher(this.voucher);
      }
    },

    saveVoucher(voucher) {
      this.isSaving = true;
      this.loading = true;
      if (voucher.id != null) {
        //Don't forward these for put operation
        delete voucher.user;

        voucher.expirationDate = voucher.validUntil;
        delete voucher.validUntil;

        VouchersApiService.put(`/${voucher.id}`, voucher)
          .then(response => {
            this.$log.debug("Voucher updated: " + response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            this.previousState();
          });
      } else {
        voucher.expirationDate = voucher.validUntil;
        delete voucher.validUntil;
        VouchersApiService.post(``, voucher)
          .then(response => {
            this.$log.debug("Voucher created: " + response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            this.previousState();
          });
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    ...mapGetters(["currentUser"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    },
    expirationDateFormatted: {
      get() {
        if (this.voucher.validUntil == null || this.voucher.validUntil === "") return null;
        return moment
          .utc(this.voucher.validUntil)
          .local()
          .format("yyyy-MM-DD HH:mm:ss");
      },
      set(value) {
        this.voucher.validUntil = value;
      }
    }
  }
};
